














































import Vue from 'vue';

export default Vue.extend({
  name: 'ToggableDatePicker',
  props: {
    value: {
      type: String as () => string,
    },
    label: {
      type: String as () => string,
    },
    rules: {
      type: Array,
    },
    messages: {
      type: [String as () => string, Array as () => string[]],
    },
    nativeLabel: {
      type: Boolean as () => boolean,
      default: false,
    },
    readonly: {
      type: Boolean as () => boolean,
      default: false,
    },
  },
  data(): {
    modal?: boolean;
    isSaving?: boolean;
  } {
    return {
      modal: false,
      isSaving: false,
    };
  },
  computed: {
    internal: {
      get(): string {
        return this.value;
      },
      set(val: string) {
        this.$emit('input', val);
      },
    },
    locale(): string {
      return (this.$i18n as any).locale;
    },
    hasContent(): boolean {
      return !!this.value;
    },
  },
  methods: {
    handleSave() {
      if (this.readonly) {
        this.modal = false;
        return;
      }
      (this.$refs.dialog as any).save(this.internal);
      this.isSaving = true;
      this.$emit('save', this.value);
    },
    markAsSaved() {
      this.isSaving = false;
    },
  },
});
