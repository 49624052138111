var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h2',[_vm._v(_vm._s(_vm.$tc('fls_activity.label')))]),_c('div',{staticClass:"d-flex"},[_c('v-select',{staticClass:"mr-2",attrs:{"label":_vm.$t('fls_activity.activity_type'),"error-messages":_vm.errorMessages["activity_type"],"loading":!_vm.value || !_vm.activityType,"items":(_vm.activityType || []).map(function (item) { return ({
          text: _vm.$langobj.parse(item.name),
          value: item.enum_key,
        }); }),"readonly":_vm.readonly},model:{value:(_vm.internal.activity_type_key),callback:function ($$v) {_vm.$set(_vm.internal, "activity_type_key", $$v)},expression:"internal.activity_type_key"}}),_c('v-select',{staticClass:"ml-2 mr-2",attrs:{"label":_vm.$t('fls_activity.request_method'),"error-messages":_vm.errorMessages["request_method"],"loading":!_vm.value || !_vm.requestMethod,"items":(_vm.requestMethod || []).map(function (item) { return ({
          text: _vm.$langobj.parse(item.name),
          value: item.enum_key,
        }); }),"readonly":_vm.readonly},model:{value:(_vm.internal.request_method_key),callback:function ($$v) {_vm.$set(_vm.internal, "request_method_key", $$v)},expression:"internal.request_method_key"}}),_c('v-select',{staticClass:"ml-2",attrs:{"label":_vm.$t('fls_activity.delivery_mode'),"error-messages":_vm.errorMessages["delivery_mode"],"loading":!_vm.value || !_vm.deliveryMode,"items":(_vm.deliveryMode || []).map(function (item) { return ({
          text: _vm.$langobj.parse(item.name),
          value: item.enum_key,
        }); }),"readonly":_vm.readonly},model:{value:(_vm.internal.delivery_mode_key),callback:function ($$v) {_vm.$set(_vm.internal, "delivery_mode_key", $$v)},expression:"internal.delivery_mode_key"}})],1),_c('tip-tap',{attrs:{"label":_vm.$t('fls_activity.description'),"error-messages":_vm.errorMessages["description"],"loading":!_vm.value,"readonly":_vm.readonly},model:{value:(_vm.internal.description),callback:function ($$v) {_vm.$set(_vm.internal, "description", $$v)},expression:"internal.description"}}),_c('v-text-field',{attrs:{"label":_vm.$t('fls_activity.source'),"error-messages":_vm.errorMessages["source"],"loading":!_vm.value,"readonly":_vm.readonly},model:{value:(_vm.internal.source),callback:function ($$v) {_vm.$set(_vm.internal, "source", $$v)},expression:"internal.source"}}),_c('div',{staticClass:"d-flex"},[_c('toggable-date-picker',{attrs:{"native-label":"","label":_vm.$t('fls_activity.received_on'),"readonly":_vm.readonly},model:{value:(_vm.internal.received_on),callback:function ($$v) {_vm.$set(_vm.internal, "received_on", $$v)},expression:"internal.received_on"}}),_c('toggable-date-picker',{staticClass:"ml-6",attrs:{"native-label":"","label":_vm.$t('fls_activity.completed_on'),"readonly":_vm.readonly},model:{value:(_vm.internal.completed_on),callback:function ($$v) {_vm.$set(_vm.internal, "completed_on", $$v)},expression:"internal.completed_on"}})],1),(_vm.internal.created_by)?_c('small',{staticClass:"grey--text"},[_c('v-icon',{attrs:{"color":"grey","small":""}},[_vm._v("mdi-clock-outline")]),_c('em',[_vm._v(" "+_vm._s(_vm.$t('fls_activity.created_by'))+" "+_vm._s(_vm.internal.created_by.first_name)+" "+_vm._s(_vm.internal.created_by.last_name)+" - "+_vm._s(_vm.$moment.utc(_vm.internal.created_at).local().format('LLLL'))+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }