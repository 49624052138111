import axios from 'axios';
import APIUtils from './APIUtils';
import LocaleUtils, { LanguageObject } from './LocaleUtils';

interface Enumeration {
  enum_id: number;
  family_key: string;
  enum_key: string;
  name: LanguageObject;
  sort: number;
  is_active: boolean;
  description: LanguageObject;
}

const enumeration = {
  generateDefault: (item?: Partial<Enumeration>): Partial<Enumeration> => {
    return {
      enum_id: item?.enum_id,
      family_key: item?.family_key,
      enum_key: item?.enum_key,
      name: LocaleUtils.langobj.generate(item?.name),
      sort: item?.sort,
      is_active: item?.is_active,
      description: LocaleUtils.langobj.generate(item?.description),
    };
  },
};

const api = {
  list: async (params?: { family_key: string }) => {
    const resp = await axios.get<Enumeration[]>('enumeration/list.php', {
      params: params,
    });
    return resp.data;
  },
  get: async (id: number) => {
    const resp = await axios.get<Enumeration>('enumeration/get.php', {
      params: { id: id },
    });
    return resp.data;
  },
  create: async (item: Partial<Enumeration>) => {
    const resp = await axios.post<Enumeration>('enumeration/create.php', {
      ...APIUtils.clean(item),
    });
    return resp.data;
  },
  update: async (item: Partial<Enumeration>) => {
    const resp = await axios.put<Enumeration>('enumeration/update.php', {
      ...APIUtils.clean(item),
    });
    return resp.data;
  },
  remove: async (id: number) => {
    const resp = await axios.delete<Enumeration>('enumeration/remove.php', {
      params: { enum_id: id },
    });
    return resp.data;
  },
};

export default { api, enumeration };
export { Enumeration };
