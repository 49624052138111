



























































































import FlsActivityUtils, { FlsActivity } from '@/utils/FlsActivityUtils';
import EnumerationUtils, { Enumeration } from '@/utils/EnumerationUtils';
import Vue from 'vue';
import ToggableDatePicker from '@/components/framework/datepicker/ToggableDatePicker.vue';

export default Vue.extend({
  components: { ToggableDatePicker },
  props: {
    value: {
      type: Object as () => Partial<FlsActivity>,
    },
    mode: {
      type: String as () => 'create' | 'edit',
      required: true,
    },
    'error-messages': {
      type: Object as () => { [key: string]: string[] },
      default: () => ({}),
    },
    readonly: {
      type: Boolean as () => boolean,
    },
  },

  computed: {
    internal: {
      get(): Partial<FlsActivity> {
        return this.value || FlsActivityUtils.flsActivity.generateDefault();
      },
      set(val: Partial<FlsActivity>) {
        this.$emit('input', val);
      },
    },
  },

  data(): {
    activityType?: Enumeration[];
    requestMethod?: Enumeration[];
    deliveryMode?: Enumeration[];
  } {
    return {
      activityType: undefined,
      requestMethod: undefined,
      deliveryMode: undefined,
    };
  },

  async mounted() {
    this.activityType = await EnumerationUtils.api.list({
      family_key: 'activity_type',
    });
    this.requestMethod = await EnumerationUtils.api.list({
      family_key: 'request_method',
    });
    this.deliveryMode = await EnumerationUtils.api.list({
      family_key: 'delivery_mode',
    });
  },
});
